import { useQuery } from "react-query"
import supabase from "../supabase"

const listClinics = async () => {
  const { data, error } = await supabase.from("clinics").select()

  if (error) {
    throw new Error(error.message)
  }

  return data
}

export default function useClinics() {
  return useQuery("clinics", () => listClinics())
}
