// import { useForm } from "react-hook-form"
// import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { Formik } from "formik"
import { useNavigate } from "react-router-dom"
import { useQuery, useMutation, useQueryClient } from "react-query"

import supabase from "../supabase"

import Button from "../components/button"
import FormItem from "../components/formItem"
import { WidgetInput } from "react-addressfinder"

import Input from "../components/input"
import Select from "../components/select"

const schema = yup
  .object({
    name: yup.string().required(),
    address_line_1: yup.string().required(),
    address_line_2: yup.string().nullable(),
    suburb: yup.string().required(),
    state: yup.string().required(),
    postcode: yup.string().required(),
    coordinates: yup.object({
      type: yup.string().required(),
      coordinates: yup.array(),
    }),
    has_ct: yup.boolean().required(),
  })
  .required()

const CreateClinicPage = () => {
  let navigate = useNavigate()
  const queryClient = useQueryClient()

  const groupsQuery = useQuery(["groups"], async () => {
    const { data, error } = await supabase.from("groups").select()

    if (error) {
      throw new Error(error.message)
    }

    return data
  })

  const { mutate } = useMutation(
    async payload => {
      const { data, error } = await supabase.from("clinics").insert([payload])

      if (error) {
        throw new Error(error.message)
      }

      return data
    },
    {
      onSuccess: (data, variables) => {
        queryClient.setQueryData(["clinics"], cache => cache.concat(data))
        navigate("/clinics")
      },
      onError: error => alert(error),
    }
  )

  return (
    <div className="my-3 flex flex-col gap-3">
      <div className="my-1 text-xl">Create a Clinic</div>
      <Formik
        initialValues={{
          name: "",
          address_line_1: "",
          address_line_2: "",
          suburb: "",
          state: "",
          postcode: "",
          coordinates: {
            coordinates: ["", ""],
            type: "Point",
          },
          has_ct: false,
        }}
        onSubmit={mutate}
        validationSchema={schema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="flex flex-col gap-4">
                <FormItem
                  label="Name"
                  htmlFor="name"
                  error={touched.name && errors.name}
                >
                  <Input
                    id="name"
                    type="text"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                </FormItem>
                <FormItem
                  label="Group"
                  htmlFor="group"
                  error={touched.group && errors.group}
                >
                  <Select
                    id="group"
                    name="group"
                    onChange={e =>
                      setFieldValue("group", e.target.value || null)
                    }
                    onBlur={handleBlur}
                    value={values.group || ""}
                  >
                    <option value=""></option>
                    {groupsQuery.data?.map(option => (
                      <option
                        key={option.id}
                        value={option.id}
                        style={{ lineHeight: "30px" }}
                      >
                        {option.name}
                      </option>
                    ))}
                  </Select>
                </FormItem>
                <FormItem
                  label="Address Line 1"
                  htmlFor="address_line_1"
                  error={touched.address_line_1 && errors.address_line_1}
                >
                  <WidgetInput
                    id="address_line_1"
                    name="address_line_1"
                    value={values.address_line_1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    addressFinderKey={process.env.REACT_APP_ADDRESSFINDER_KEY}
                    className="bg-gray-200 py-2 px-3 rounded-md w-full"
                    raw
                    onSelected={(_, address) => {
                      setFieldValue("address_line_1", address.address_line_1)
                      setFieldValue("address_line_2", address.address_line_2)
                      setFieldValue("suburb", address.locality_name)
                      setFieldValue("state", address.state_territory)
                      setFieldValue("postcode", address.postcode)
                      setFieldValue(
                        "coordinates.coordinates[1]",
                        address.latitude
                      )
                      setFieldValue(
                        "coordinates.coordinates[0]",
                        address.longitude
                      )
                    }}
                  />
                </FormItem>
                <FormItem
                  label="Address Line 2"
                  htmlFor="address_line_2"
                  error={touched.address_line_2 && errors.address_line_2}
                >
                  <Input
                    id="address_line_2"
                    type="text"
                    name="address_line_2"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address_line_2 ?? ""}
                  />
                </FormItem>
                <FormItem
                  label="Suburb"
                  htmlFor="suburb"
                  error={touched.suburb && errors.suburb}
                >
                  <Input
                    id="suburb"
                    type="text"
                    name="suburb"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.suburb}
                  />
                </FormItem>
                <FormItem
                  label="State"
                  htmlFor="state"
                  error={touched.state && errors.state}
                >
                  <Input
                    id="state"
                    type="text"
                    name="state"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.state}
                  />
                </FormItem>
                <FormItem
                  label="Postcode"
                  htmlFor="postcode"
                  error={touched.postcode && errors.postcode}
                >
                  <Input
                    id="postcode"
                    type="text"
                    name="postcode"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.postcode}
                  />
                </FormItem>
                <FormItem
                  label="Latitude"
                  htmlFor="lat"
                  error={
                    touched.coordinates?.coordinates[1] &&
                    errors.coordinates?.coordinates[1]
                  }
                >
                  <Input
                    id="lat"
                    type="text"
                    name="coordinates.coordinates[1]"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.coordinates.coordinates[1]}
                  />
                </FormItem>
                <FormItem
                  label="Longitude"
                  htmlFor="lng"
                  error={
                    touched.coordinates?.coordinates[0] &&
                    errors.coordinates?.coordinates[0]
                  }
                >
                  <Input
                    id="lng"
                    type="text"
                    name="coordinates.coordinates[0]"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.coordinates.coordinates[0]}
                  />
                </FormItem>
                <FormItem
                  label="Has CT?"
                  htmlFor="has_ct"
                  error={touched.has_ct && errors.has_ct}
                >
                  <div>
                    <input
                      type="checkbox"
                      id="has_ct"
                      checked={values.has_ct}
                      name="has_ct"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </FormItem>
                <Button type="submit">Submit</Button>
              </div>
            </form>
          )
        }}
      </Formik>
    </div>
  )
}

export default CreateClinicPage
