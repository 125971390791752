import React, { useState } from "react"
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete"

import NearestClinics from "../components/nearestClinics"
import ComboBox from "../components/comboBox"
import useClinicSearch from "../hooks/useClinicSearch"

const SearchPage = () => {
  const [coordinates, setCoordinates] = useState()
  const [isGeocoding, setIsGeocoding] = useState(false)

  const {
    data: clinics,
    error,
    // fetchNextPage,
    // hasNextPage,
    // isFetching,
    // isFetchingNextPage,
    status,
  } = useClinicSearch({ coordinates }, { enabled: !!coordinates })

  const {
    value,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: "au",
      },
      types: ["(regions)"],
    },
    debounce: 300,
  })

  return (
    <div
      style={{
        height: "100vh",
        overflowY: "hidden",
      }}
      className="flex flex-col"
    >
      <div style={{ display: "flex", gap: ".5rem" }} className="py-3">
        <div style={{ width: "100%" }}>
          <ComboBox
            placeholder="Enter a Suburb or Postcode to find the nearest clinic"
            value={value}
            onChange={e => setValue(e.target.value)}
            suggestions={data.map(d => d.description)}
            onSelect={async description => {
              setIsGeocoding(true)
              setValue(description, false)
              clearSuggestions()
              const results = await getGeocode({ address: description })
              setCoordinates(getLatLng(results[0]))
              setIsGeocoding(false)
            }}
          />
        </div>
      </div>
      <div style={{ overflowY: "auto" }}>
        {status === "loading" || isGeocoding ? (
          <div>Loading...</div>
        ) : status === "error" ? (
          <div>Error: {error.message}</div>
        ) : (
          clinics && (
            <NearestClinics clinics={[].concat.apply([], clinics.pages)} />
          )
        )}
      </div>
    </div>
  )
}

export default SearchPage
