import { Link, NavLink, Outlet } from "react-router-dom"
import { useAuth } from "../contexts/Auth"

const Layout = () => {
  const { user } = useAuth()

  return (
    <div className="flex">
      <div
        className="p-3 flex flex-col justify-between h-screen"
        // style={{ maxWidth: "300px" }}
      >
        <div className="flex flex-col">
          <NavLink
            to="/"
            className={({ isActive }) =>
              "py-1 px-4 rounded-md hover:text-primary" +
              " " +
              (isActive ? "text-primary" : "")
            }
          >
            Search
          </NavLink>
          <NavLink
            to="/clinics"
            className={({ isActive }) =>
              "py-1 px-4 rounded-md hover:text-primary" +
              " " +
              (isActive ? "text-primary" : "")
            }
          >
            Clinics
          </NavLink>
          <NavLink
            to="/groups"
            className={({ isActive }) =>
              "py-1 px-4 rounded-md hover:text-primary" +
              " " +
              (isActive ? "text-primary" : "")
            }
          >
            Groups
          </NavLink>
        </div>
        <Link to="profile">
          <div className="py-1 px-4 cursor-pointer hover:text-primary">
            {user.email}
          </div>
        </Link>
      </div>
      <div className="flex-1 md:container px-3">
        <Outlet />
      </div>
    </div>
  )
}

export default Layout
