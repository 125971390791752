import { Navigate } from "react-router-dom"

import { useAuth } from "../contexts/Auth"

import Button from "../components/button"

const LoginPage = () => {
  const { user, signIn } = useAuth()
  if (user) return <Navigate to="/" replace />
  return (
    <Button
      onClick={async () => {
        try {
          await signIn()
        } catch (e) {
          alert(e.message)
        }
      }}
    >
      Login
    </Button>
  )
}

export default LoginPage
