import React, { useContext, useState, useEffect } from "react"
import supabase from "../supabase"

const AuthContext = React.createContext()

export function AuthProvider({ children }) {
  const [user, setUser] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Check active sessions and sets the user
    const session = supabase.auth.session()

    setUser(session?.user ?? null)
    setLoading(false)

    // Listen for changes on auth state (logged in, signed out, etc.)
    const { data: listener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        setUser(session?.user ?? null)
        setLoading(false)
      }
    )

    return () => {
      listener?.unsubscribe()
    }
  }, [])

  // Will be passed down to Signup, Login and Dashboard components
  const value = {
    signUp: data => supabase.auth.signUp(data),
    signIn: async () => {
      const { user, error } = await supabase.auth.signIn(
        {
          provider: "azure",
        },
        { scopes: "email" }
      )

      if (error) {
        return Promise.reject(error)
      }

      if (user) {
        return Promise.resolve()
      }
    },

    signOut: () => supabase.auth.signOut(),
    user,
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext)
}
