import Input from "./input"

const ComboBox = ({
  onChange,
  onSelect,
  value,
  disabled,
  placeholder,
  suggestions,
}) => {
  return (
    <div style={{ width: "100%", position: "relative" }}>
      <Input
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
      />
      {suggestions && (
        <div
          className="mt-1"
          style={{ background: "white", position: "absolute", width: "100%" }}
        >
          {suggestions.map(suggestion => (
            <div
              key={suggestion}
              onClick={() => onSelect(suggestion)}
              className="p-3 bg-gray-200 cursor-pointer hover:bg-primary hover:text-white first:rounded-t-md last:rounded-b-md shadow-lg"
            >
              {suggestion}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default ComboBox
