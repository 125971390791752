import { useNavigate } from "react-router-dom"

const NearestClinics = ({ clinics }) => {
  const navigate = useNavigate()
  return (
    <>
      {clinics.map(clinic => (
        <div
          key={clinic.id}
          className="px-4 py-2 cursor-pointer hover:bg-gray-200 rounded-md"
          onClick={() => navigate(`clinics/${clinic.id}`)}
        >
          <div>
            {clinic.name} - {(clinic.distance / 1000).toFixed(1)}km
          </div>
          <div className="text-sm text-black/50">
            {clinic.address_line_1 +
              " " +
              (clinic.address_line_2 ? clinic.address_line_2 + " " : "") +
              clinic.suburb +
              " " +
              clinic.state +
              " " +
              clinic.postcode}
          </div>
        </div>
      ))}
    </>
  )
}

export default NearestClinics
