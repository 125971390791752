import * as yup from "yup"
import { Formik } from "formik"
import { useNavigate } from "react-router-dom"

import supabase from "../supabase"

import Button from "../components/button"
import FormItem from "../components/formItem"
import Input from "../components/input"
import { useMutation, useQueryClient } from "react-query"

const schema = yup
  .object({
    name: yup.string().required(),
  })
  .required()

const CreateGroupPage = () => {
  const queryClient = useQueryClient()
  const { mutate } = useMutation(
    async variables => {
      const { data, error } = await supabase.from("groups").insert([variables])

      if (error) {
        throw new Error(error.message)
      }

      return data
    },
    {
      onSuccess: data => {
        queryClient.setQueryData(["groups"], cache => cache.concat(data))
        navigate("/groups")
      },
    }
  )

  let navigate = useNavigate()

  return (
    <div className="my-3 flex flex-col gap-3">
      <div className="my-1 text-xl">Create a Group</div>
      <Formik
        initialValues={{
          name: "",
        }}
        onSubmit={async values => {
          await mutate(values)
        }}
        validationSchema={schema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="flex flex-col gap-4">
              <FormItem
                label="Name"
                htmlFor="name"
                error={touched.name && errors.name}
              >
                <Input
                  id="name"
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
              </FormItem>
              <Button type="submit">Submit</Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default CreateGroupPage
