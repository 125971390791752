// import { useForm } from "react-hook-form"
// import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { Formik } from "formik"
import { useNavigate, useParams } from "react-router-dom"
import { useQuery, useMutation, useQueryClient } from "react-query"

import supabase from "../supabase"

import Button from "../components/button"
import FormItem from "../components/formItem"

import Input from "../components/input"

const schema = yup
  .object({
    name: yup.string().required(),
  })
  .required()

const GroupsShowPage = () => {
  let navigate = useNavigate()
  const params = useParams()

  const { data } = useQuery(["groups", params.id], async () => {
    const { data, error } = await supabase
      .from("groups")
      .select()
      .eq("id", params.id)
      .single()

    if (error) {
      throw new Error(error.message)
    }

    return data
  })

  const queryClient = useQueryClient()
  const { mutate } = useMutation(
    async variables => {
      const { data, error } = await supabase
        .from("groups")
        .update(variables)
        .match({ id: params.id })
        .single()

      if (error) {
        throw new Error(error.message)
      }

      return data
    },
    {
      onSuccess: data => {
        queryClient.setQueryData(["groups", params.id], data)
        navigate("/groups")
      },
    }
  )

  return (
    <div className="my-3 flex flex-col gap-3">
      <div className="my-1 text-xl">Edit Clinic</div>
      {data && (
        <Formik
          initialValues={data}
          onSubmit={async values => {
            console.log(values)
            await mutate(values)
          }}
          validationSchema={schema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="flex flex-col gap-4">
                <FormItem
                  label="Name"
                  htmlFor="name"
                  error={touched.name && errors.name}
                >
                  <Input
                    id="name"
                    type="text"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                </FormItem>
                <Button type="submit">Submit</Button>
              </div>
            </form>
          )}
        </Formik>
      )}
    </div>
  )
}

export default GroupsShowPage
