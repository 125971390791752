import { useState } from "react"
import {
  createTable,
  getCoreRowModel,
  useTableInstance,
} from "@tanstack/react-table"
import { useQuery } from "react-query"

import Button from "../components/button"
import { Link } from "react-router-dom"
import Table from "../components/table"
import supabase from "../supabase"

const table = createTable()

const defaultColumns = [
  table.createDataColumn(row => row.name, {
    id: "#",
    header: () => <div style={{ textAlign: "left" }}>#</div>,
    cell: info => {
      return <div style={{ textAlign: "left" }}>{info.row.index}</div>
    },
  }),
  table.createDataColumn(row => row.name, {
    id: "name",
    cell: info => (
      <Link to={info.row.original.id + ""}>
        <div className="hover:underline">{info.getValue()}</div>
      </Link>
    ),
    header: () => <div style={{ textAlign: "left" }}>Name</div>,
  }),
]

const GroupsPage = () => {
  const [columns] = useState(() => [...defaultColumns])

  const { data, error, isLoading } = useQuery("groups", async () => {
    const { data, error } = await supabase.from("groups").select()

    if (error) {
      throw new Error(error.message)
    }

    return data
  })

  const instance = useTableInstance(table, {
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <div className="flex flex-col">
      <div className="flex py-3">
        <div className="flex-1 text-xl my-1">Groups</div>
        <Link to="create">
          <Button>Create</Button>
        </Link>
      </div>
      <div>
        {isLoading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error.message}</div>
        ) : (
          <Table
            rows={instance.getRowModel().rows}
            columns={instance.getHeaderGroups()}
          />
        )}
      </div>
    </div>
  )
}

export default GroupsPage
