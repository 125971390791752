import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"

import SearchPage from "./pages/searchPage"

import ProfilePage from "./pages/profilePage"

import LoginPage from "./pages/loginPage"

import ClinicsListPage from "./pages/clinicsListPage"
import ClinicsShowPage from "./pages/clinicsShowPage"
import ClinicsCreatePage from "./pages/clinicsCreatePage"

import GroupsListPage from "./pages/groupsListPage"
import GroupsShowPage from "./pages/groupsShowPage"
import GroupsCreatePage from "./pages/groupsCreatePage"

import { Routes, Route, BrowserRouter } from "react-router-dom"
import { AuthProvider } from "./contexts/Auth"
import PrivateRoute from "./components/privateRoute"
import Layout from "./components/layout"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
})

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route element={<PrivateRoute />}>
              <Route element={<Layout />}>
                <Route path="/" element={<SearchPage />} />
                <Route path="/clinics" element={<ClinicsListPage />} />
                <Route path="/clinics/:id" element={<ClinicsShowPage />} />
                <Route path="/clinics/create" element={<ClinicsCreatePage />} />
                <Route path="/groups" element={<GroupsListPage />} />
                <Route path="/groups/:id" element={<GroupsShowPage />} />
                <Route path="/groups/create" element={<GroupsCreatePage />} />
                <Route path="/profile" element={<ProfilePage />} />
              </Route>
            </Route>
            <Route path="/login" element={<LoginPage />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
      {/* <div>App</div> */}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default App
