import { useState } from "react"
import {
  createTable,
  getCoreRowModel,
  useTableInstance,
} from "@tanstack/react-table"

import Button from "../components/button"
import useClinics from "../hooks/useClinics"
import { Link } from "react-router-dom"
import Table from "../components/table"

const table = createTable()

const defaultColumns = [
  table.createDataColumn(row => row.name, {
    id: "#",
    header: () => <div style={{ textAlign: "left" }}>#</div>,
    cell: info => {
      return <div style={{ textAlign: "left" }}>{info.row.index}</div>
    },
  }),
  table.createDataColumn(row => row.name, {
    id: "name",
    cell: info => {
      return (
        <Link to={info.row.original.id + ""}>
          <div className="hover:underline">{info.getValue()}</div>
        </Link>
      )
    },
    header: () => <div style={{ textAlign: "left" }}>Name</div>,
  }),
  table.createDataColumn(row => row.suburb, {
    id: "suburb",
    cell: info => info.getValue(),
    header: () => <div style={{ textAlign: "left" }}>Suburb</div>,
  }),
  table.createDataColumn(row => row.state, {
    id: "state",
    cell: info => info.getValue(),
    header: () => <div style={{ textAlign: "left" }}>State</div>,
  }),
  table.createDataColumn(row => row.postcode, {
    id: "postcode",
    cell: info => info.getValue(),
    header: () => <div style={{ textAlign: "left" }}>Postcode</div>,
  }),
]

const ClinicsPage = () => {
  const [columns] = useState(() => [...defaultColumns])

  const { data, error, isLoading } = useClinics()

  const instance = useTableInstance(table, {
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <div className="flex flex-col">
      <div className="flex py-3">
        <div className="flex-1 text-xl my-1">Clinics</div>
        <Link to="create">
          <Button>Create</Button>
        </Link>
      </div>
      <div>
        {isLoading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error.message}</div>
        ) : (
          <Table
            rows={instance.getRowModel().rows}
            columns={instance.getHeaderGroups()}
          />
        )}
      </div>
    </div>
  )
}

export default ClinicsPage
