const Select = ({
  children,
  id,
  onChange,
  onBlur,
  name,
  value,
  placeholder,
}) => {
  return (
    <select
      id={id}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      name={name}
      placeholder={placeholder}
      className="bg-gray-200 py-1 px-3 rounded-md w-full cursor-pointer"
      style={{ appearance: "none" }}
    >
      {children}
    </select>
  )
}

export default Select
