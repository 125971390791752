import React from "react"
import { Navigate, Outlet } from "react-router-dom"
import { useAuth } from "../contexts/Auth"

const PrivateRoute = () => {
  const { user } = useAuth()

  if (!user) {
    return <Navigate to="/login" replace />
  }
  return <Outlet />
}

export default PrivateRoute
