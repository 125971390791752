import React from "react"

const Input = ({ id, onChange, onBlur, name, value, placeholder }) => {
  return (
    <input
      id={id}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      name={name}
      placeholder={placeholder}
      className="bg-gray-200 py-1 px-3 rounded-md w-full"
    />
  )
}

export default Input
