const FormItem = ({ children, label, htmlFor, error }) => {
  return (
    <div className="flex flex-col gap-1">
      {label && (
        <label htmlFor={htmlFor} className="text-sm">
          {label}
        </label>
      )}
      {children}
      {error && <div className="text-red-500 text-sm">{error}</div>}
    </div>
  )
}

export default FormItem
