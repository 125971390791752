import styled from "styled-components"

const Table = ({ columns, rows }) => {
  return (
    <table className="w-full table-auto">
      <thead>
        {columns.map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <TableHeader key={header.id} colSpan={header.colSpan}>
                {header.isPlaceholder ? null : header.renderHeader()}
              </TableHeader>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {rows.map(row => (
          <tr key={row.id}>
            {row.getVisibleCells().map(cell => (
              <TableCell key={cell.id}>{cell.renderCell()}</TableCell>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

const TableHeader = styled.th`
  border-bottom: 1px solid rgb(220, 220, 220);
  opacity: 0.5;
  font-weight: 400;
  font-size: 0.8rem;
  text-align: ${props => props.align};
  padding: 0.75rem 1rem;
  text-transform: uppercase;
`

const TableCell = styled.td`
  text-align: ${props => props.align};
  padding: 0.75rem 1rem;
  /* border-bottom: 1px solid black; */
`

export default Table
